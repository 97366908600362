export const Bio = {
  name: "Tanveer Mohit",
  roles: [
    "Full Stack Developer",
    "Web/Mobile Developer",
    "Networking Student",
    "Cyber Security Graduate",
  ],
  description:
    "Motivated young student with passion to progress within the IT Industry. Currently hold a Diploma in IT (Cyber Security) by 20 yrs old. Acquired knowledge and experiences in Networking fundamentals, Cyber Security, hands on practice with Wireshark, Nmap, Linux Environments, Mobile App & Web Development, UI & UX Design, Computer Vision (AI). Through participation in competitions, I acquired skills such as Public Speaking, Creative & Critical Thinking, Time Management, Work Ethics, Leading a Team, Support each others and also working as a team in projects. Adaptive to any situation, work under pressure, problem solving, fast learner, disciplined.",
  github: "https://github.com/hackertanveer",
  resume: "",
  linkedin: "https://www.linkedin.com/in/tanveer-mohit-18662b261/",
  
};

export const skills = [
  {
    title: "Languages",
    skills: [
      {
        name: "HTML",
        image:
          "https://skillicons.dev/icons?i=html",
      },
      {
        name: "CSS",
        image:
          "https://skillicons.dev/icons?i=css",
      },
      {
        name: "Javascript",
        image:
          "https://skillicons.dev/icons?i=javascript",
      },
      {
        name: "C",
        image: "https://skillicons.dev/icons?i=c",
      },
      {
        name: "Java",
        image:
          "https://skillicons.dev/icons?i=java",
      },
      {
        name: "Dart",
        image:
          "https://skillicons.dev/icons?i=dart",
      },
      {
        name: "Python",
        image:
          "https://skillicons.dev/icons?i=python",
      },
      {
        name: "C Sharp",
        image:
          "https://skillicons.dev/icons?i=cs",
      },
      {
        name: "Solidity",
        image:
          "https://skillicons.dev/icons?i=solidity",
      },
      
    ],
  },
  {
    title: "Frameworks",
    skills: [
      {
        name: "Eclipse",
        image: "https://skillicons.dev/icons?i=eclipse",
      },
      {
        name: "Flutter",
        image:
          "https://skillicons.dev/icons?i=flutter",
      },
      {
        name: "React js & Native",
        image: "https://skillicons.dev/icons?i=react",
      },
      {
        name: "Vite js",
        image:
          "https://skillicons.dev/icons?i=vite",
      },
      {
        name: "Next js",
        image:
          "https://skillicons.dev/icons?i=nextjs",
      },
 
      {
        name: "Node js",
        image:
          "https://skillicons.dev/icons?i=nodejs",
      },
      {
        name: ".Net Core",
        image: "https://skillicons.dev/icons?i=dotnet",
      },
      {
        name: "Vue Js",
        image: "https://skillicons.dev/icons?i=vuejs",
      },
      {
        name: "TensorFlow",
        image:
          "https://skillicons.dev/icons?i=tensorflow",
      },
      {
        name: "Sqlite",
        image: "https://skillicons.dev/icons?i=sqlite",
      },
    ],
  },
  {
    title: "DevOps & Cloud Tools",
    skills: [
      {
        name: "Vs Code",
        image:
          "https://skillicons.dev/icons?i=vscode",
      },
      {
        name: "Git",
        image:
          "https://skillicons.dev/icons?i=git",
      },
      {
        name: "Github",
        image:
          "https://skillicons.dev/icons?i=github",
      },
      {
        name: "Figma",
        image:
          "https://skillicons.dev/icons?i=figma",
      },
      {
        name: "Google Cloud Provider",
        image:
          "https://skillicons.dev/icons?i=gcp",
      },
      {
        name: "Firebase",
        image:
          "https://skillicons.dev/icons?i=firebase",
      },
      {
        name: "Netlify",
        image:
          "https://skillicons.dev/icons?i=netlify",
      },

      {
        name: "Heroku",
        image:
          "https://skillicons.dev/icons?i=heroku",
      },
      {
        name: "Azure",
        image:
          "https://skillicons.dev/icons?i=azure",
      },
    ],
  },
  {
    title: "Additional Languages and Tools",
    skills: [
      {
        name: "Bootstrap",
        image:
          "https://skillicons.dev/icons?i=bootstrap",
      },
      {
        name: "TailWind CSS",
        image:
          "https://skillicons.dev/icons?i=tailwindcss",
      },
      {
        name: "Express.js",
        image:
          "https://skillicons.dev/icons?i=express",
      },
      {
        name: "Ardunino",
        image:
          "https://skillicons.dev/icons?i=arduino",
      },
      {
        name: "Linux",
        image:
          "https://skillicons.dev/icons?i=linux",
      },
      {
        name: "MongoDB",
        image:
          "https://skillicons.dev/icons?i=mongodb",
      },
      {
        name: "MySQL",
        image:
          "https://skillicons.dev/icons?i=mysql",
      },
      {
        name: "Postgresql",
        image:
          "https://skillicons.dev/icons?i=postgresql",
      },
      {
        name: "Postman",
        image:
          "https://skillicons.dev/icons?i=postman",
      },
      {
        name: "Redux",
        image:
          "https://skillicons.dev/icons?i=redux",
      },
      {
        name: "Docker",
        image:
          "https://skillicons.dev/icons?i=docker",
      },
      {
        name: "Kubernetes",
        image:
          "https://skillicons.dev/icons?i=kubernetes",
      },
    ],
  },
];

export const experiences = [
  {
    id: 2,
    img: "https://rogerscapital.mu/wp-content/uploads/2023/12/313406692_5551497761594189_7304562146784144359_n.jpg",
    role: "Cyber Security Analyst",
    company: "Rogers Capital Technology Services Ltd",
    date: "Nov 2024 - Jan 2025",
    desc: "As a Cyber Security Analyst at Rogers Capital Technology Services Ltd, I been protecting and securing IT assets against cyber attacks. I made a cyber security automation tool as a contribution of my past experience to better improve the company's effeciency .",
    skills: [
      "Cyber Defence",
      "Penetration Testing",
      "Threat & Vulnerability Management",
      "Reporting",
      "Administrative task",
      "Email pentest",
      "Malware Pentest",
      "External Ip Pentest",
      "Web application pentest",
      "Build Automation Tools",
    ]
    
  },
  {
    id: 1,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEOHvLYIc8JEQ3ywB7Rf7VTRYuPgk6ioa1KK4Ua_QhdQ&s",
    role: "Junior Software Developer",
    company: "Zapproach Ltd",
    date: "Nov 2023 - Aug 2024",
    desc: "As a Software Engineer at Zapproach Ltd, I have worked on several projects so far from mobile app to website development.",
    skills: [
      "React Native Expo",
      "Axios",
      "Rest API/ Features Integration",
      "Yup",
      "Azure Dev Ops",
      "Scrum Meeting",
      "User Story",
      "Tailwind CSS",
      "Source Tree",
      "Git",
      "Github",
      "C#",
      ".Net Core",
      "Razor",
      "Blazor",
      "Vue.js",
      "Postman",
      "Next.js"
    ]
    
  },
  {
    id: 0,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEOHvLYIc8JEQ3ywB7Rf7VTRYuPgk6ioa1KK4Ua_QhdQ&s",
    role: "Software Engineer Intern",
    company: "Zapproach Ltd",
    date: "Aug 2023 - Oct 2023",
    
    skills: [
      "React Native",
      "Microsoft SQL Server",
      "C#",
      ".NET Core",
      ".NET Framework",
      "ASP.NET Web API",
      "ASP.NET MVC",
      "Entity Framework (EF) Core",
      "JSON Web Token (JWT)",
      "REST APIs",
      "Git",
      "Github",
      "Redux",
      "MongoDB",
      "Node.js",
      "Express.js",
      "Postman",
      "API Testing",
      "Server Deployment"
    ],
    
  },
  
  
];

export const education = [
  // {
  //   id: 1,
  //   img: "https://yt3.ggpht.com/a/AGF-l79xLJH7uZE2d6_0OlOF7lWLngyG1a5dH5527A=s900-c-k-c0xffffffff-no-rj-mo",
  //   school: "IELTS Academic",
  //   date: "August 2024",
  //   grade: "6.0",
  //   desc: "Skill Level: Competent",
  //   degree: "",
    
  // },

  {
    id: 3,
    img: "https://cdn.murdoch.edu.au/mu-images/default-source/logos/national-institute-technology.tmb-300-reduce.jpg?Culture=en&sfvrsn=f6d20351_1",
    school: "National Institute of Technology",
    date: "Jan 2025 - Jan 2027 (current)" ,
    grade: "",
    desc: "Currently on Advanced Diploma in Telecomunication and Networking, to gain more practical experience in other fields and maybe one day I hope to become a manager in an IT company.",
    degree: "Advanced Diploma of Information Technology",
    
  },

  {
    id: 2,
    img: "https://yt3.ggpht.com/a/AGF-l79xLJH7uZE2d6_0OlOF7lWLngyG1a5dH5527A=s900-c-k-c0xffffffff-no-rj-mo",
    school: "IELTS Academic",
    date: "August 2024",
    grade: "6.0",
    desc: "Skill Level: Competent",
    degree: "",

  },
  {
    
    id: 1,
    img: "https://1120688276.rsc.cdn77.org/admin/uploads/images/17148/logo/large/logo.png",
    school: "University Malaysia of Computer Science & Engineering, UNIMY",
    date: "Nov 2020 - Nov 2023",
    grade: "Second class 1st division",
    desc: "Diploma in Cyber Security and Engineering at Polytechnics Mauritius Ltd. I have got skills and knowledge , on Eclipse, Digital Forencis, Cloud Infrastructure, Ethical Hacking, Software Development Life Cycle(SDLC), Virtual Machines, Microsoft SQL Server, Cryptography,  Networking, C (Programming Language), Java, Object-Oriented Programming(OOP), CyberSecurity, SQL, Cisco Packet Tracer, Linux, Nmap, Wireshark, where I am learning and working on exciting projects with a team of talented developers.",
    degree: "Diploma of Education in Information Technology (Cyber Security)",
    
  },

  {
    id: 0,
    img: "https://scontent.fper12-1.fna.fbcdn.net/v/t39.30808-1/310627098_632534665232721_2259983044476843608_n.jpg?stp=c6.0.576.576a_dst-jpg_s200x200_tt6&_nc_cat=108&ccb=1-7&_nc_sid=2d3e12&_nc_ohc=Q4HPtr34IJQQ7kNvgGC1sjx&_nc_zt=24&_nc_ht=scontent.fper12-1.fna&_nc_gid=AVPgFRZKP1LaasEV5rDsdvh&oh=00_AYC5A3BW_2C28Ar0Bgir0sFmVp5Ifw4PYyNZIyNIatCxKA&oe=67A4F0D3",
    school: "Beekrumsingh Ramlallah State Secondary School",
    date: "Nov 2018 - June 2021",
    grade: " 5 Credits",
    desc: "Education was delayed due to lockdown in Mauritius.",
    degree: "School Certificate",
    
  },
 
  
];

export const projects = [
  {
    id: 0,
    title: "Orinix.AI",
    date: "May 2023 - May 2023",
    youtube: "https://youtu.be/-FQIS8jfSKA?si=B9D1hYf1jSn80DF8",
    description:
      "An AI that analyses and decodes your dreams to predict, school difficulties, illness and much more.",
    image:
      "https://user-images.githubusercontent.com/146033345/271842102-81960f96-3e9b-41f1-8a02-ad42d643f025.png",
    tags: [
      "React JS",
      "Vite JS",
      "HTML",
      "CSS",
      "Javascript",
      "PHP",
      "Node Js",
      "MySQL",
    ],
    category: "web app",
    github: "https://github.com/HackerTanveer/Orinix.AI-Cyber-X.git",
    webapp: "https://youtu.be/-FQIS8jfSKA?si=B9D1hYf1jSn80DF8",
  },
  {
    id: 1,
    title: "FinLife",
    date: "June 2023 - June 2023",
    description:
      "Fin Life game provides an interactive and immersive experience where players can navigate through different stages of life and face realistic scenarios. From childhood to old age, players will make choices that impact their character's life, career, relationships, and financial well-being.",
    image:
      "https://user-images.githubusercontent.com/146033345/279616412-d68d6568-2679-46bf-aece-9199a2f78ce5.png",
    tags: [
      "React.js",
      "HTML",
      "CSS",
      "Javascript",
    ],
    category: "web app",
    github: "https://github.com/HackerTanveer/FinLife.git",
    webapp: "https://github.com/HackerTanveer/FinLife.git",
  },
  {
    id: 2,
    title: "RecruitSmart AI",
    date: "Oct 2023 - Oct 2023",
    description:
      "It is a webapp that analyses resumes and categories it according to required departments. Based on the information gathered can also provide specific courses (through Youtube videos) to upgrade employees skills. ",
    image:
      "https://user-images.githubusercontent.com/146033345/279679744-714fef24-4d3c-4d38-b42f-fd4f6f86966f.png",
    tags: [
      "Streamlit",
      "Python",
      "PHP",
      "MySQL",
    ],
    category: "machine learning",
    github: "https://github.com/HackerTanveer/RecruitSmart-AI.git",
    webapp: "https://github.com/HackerTanveer/RecruitSmart-AI.git",
  },
  {
    id: 3,
    title: "Beach Resort",
    date: "May 2022 - July 2022",
    description:
      "It is a website which I built during my freelance journey.",
    image:
      "https://github-production-user-asset-6210df.s3.amazonaws.com/146033345/294165298-a5083362-a9d7-42ef-b919-1ded1781cdd6.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVCODYLSA53PQK4ZA%2F20240908%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240908T203043Z&X-Amz-Expires=300&X-Amz-Signature=2c7dd743726062948cc74c7d0145707fb29454e4e8bbccbc72ee435d1fb9127e&X-Amz-SignedHeaders=host&actor_id=146033345&key_id=0&repo_id=714228658",
    tags: [
      "React js",
      "Html",
      "CSS",
      "Javascript",
    ],
    category: "web app",
    github: "",
    webapp: "https://beach-resort-xntw.onrender.com",
  },
  {
    id: 4,
    title: "ASL Education App",
    date: "Sep 2021 - Nov 2021",
    description:
      "American Sign Language (ASL) is a visual language that serves as the predominant sign language communities. This sign language illustration is created by Tanveer Mohit",
    image:
      "https://drive.google.com/uc?id=1xKa7iodlprmky-n0GZ6BXSEtvgerw5s4",
    tags: [
      "TensorFlow",
      "Next js",
      "TensorFlow js",
      "Fingerpose",
    ],
    category: "machine learning",
    github: "",
    webapp: "https://asl-education-app.netlify.app/",
  }
  
  
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
